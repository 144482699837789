// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/builds/mv-winterbach/mv-winterbach.de/src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/builds/mv-winterbach/mv-winterbach.de/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelles-js": () => import("/builds/mv-winterbach/mv-winterbach.de/src/pages/aktuelles.js" /* webpackChunkName: "component---src-pages-aktuelles-js" */),
  "component---src-pages-altpapier-js": () => import("/builds/mv-winterbach/mv-winterbach.de/src/pages/altpapier.js" /* webpackChunkName: "component---src-pages-altpapier-js" */),
  "component---src-pages-ausbildung-js": () => import("/builds/mv-winterbach/mv-winterbach.de/src/pages/ausbildung.js" /* webpackChunkName: "component---src-pages-ausbildung-js" */),
  "component---src-pages-datenschutz-js": () => import("/builds/mv-winterbach/mv-winterbach.de/src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("/builds/mv-winterbach/mv-winterbach.de/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/builds/mv-winterbach/mv-winterbach.de/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intern-download-2020-index-js": () => import("/builds/mv-winterbach/mv-winterbach.de/src/pages/intern/download/2020/index.js" /* webpackChunkName: "component---src-pages-intern-download-2020-index-js" */),
  "component---src-pages-intern-download-2023-index-js": () => import("/builds/mv-winterbach/mv-winterbach.de/src/pages/intern/download/2023/index.js" /* webpackChunkName: "component---src-pages-intern-download-2023-index-js" */),
  "component---src-pages-intern-download-2024-index-js": () => import("/builds/mv-winterbach/mv-winterbach.de/src/pages/intern/download/2024/index.js" /* webpackChunkName: "component---src-pages-intern-download-2024-index-js" */),
  "component---src-pages-juka-50-index-js": () => import("/builds/mv-winterbach/mv-winterbach.de/src/pages/juka-50/index.js" /* webpackChunkName: "component---src-pages-juka-50-index-js" */),
  "component---src-pages-kontakt-js": () => import("/builds/mv-winterbach/mv-winterbach.de/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-presse-js": () => import("/builds/mv-winterbach/mv-winterbach.de/src/pages/presse.js" /* webpackChunkName: "component---src-pages-presse-js" */),
  "component---src-pages-verein-frauentreff-js": () => import("/builds/mv-winterbach/mv-winterbach.de/src/pages/verein/frauentreff.js" /* webpackChunkName: "component---src-pages-verein-frauentreff-js" */),
  "component---src-pages-verein-index-js": () => import("/builds/mv-winterbach/mv-winterbach.de/src/pages/verein/index.js" /* webpackChunkName: "component---src-pages-verein-index-js" */)
}

